import React, { Fragment, useState } from "react";
import Email from './smtp';
import styled from 'styled-components';
import { Formik, Form, Field, FieldArray, FormikHelpers } from "formik";
import ConfirmationDialog from './ConfirmationDialog';
import { ReactComponent as Plus } from './icons8-plus.svg';
import { ReactComponent as SvgCancel } from './cancel.svg';

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: white;
  border: none;
  color: #f8c0c4;
  transition: 0.3s all;
  &&&& {
    margin: 0;
  }
  > svg {
    fill: #f8c0c4;
    &:hover {
      fill: #ff9ca3;
    }
  }
`;

const SendButton = styled.button`
  display: flex;
  margin-left: auto;
  margin-top: 1rem;
  background-color: #f8c0c4;
  border: none;
  font-size: 1em;
  border-radius: 5px;
  padding: 1rem 2rem;
  font-weight: 600;
  &:hover {
    background-color: #ff9ca3;
  }
`;

const Container = styled.div`
  margin: 1rem 6rem;
  padding: 1rem;
  @media (max-width: 415px) {
    margin: 1rem;
  }
`;

const FieldContainer = styled.div`
  position: relative;
  display: flex;
  @media (max-width: 525px) {
    flex-direction: column;
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  left: -2.7rem;
  top: 4.6rem;
  font-weight: 500;
  border: none;
  border-radius: 500px;
  width: 30px;
  height: 30px;
  padding-top: 0.3rem;
  background-color: white;
  > svg {
    fill: rgb(105, 105, 105);
    &:hover {
      fill: #f8c0c4;
    }
  }
`;

const AddContainer = styled.div`
  cursor: pointer;
  display: flex;
  width: 50%;
  align-items: center;
  margin-top: 1rem;
  > button {
    margin: 0 1rem 0 0;
  }
  > p {
    margin: 0;
    font-weight: 500px;
    font-size: 0.8em;
  }
`;

const InputContainer = styled.div`
  margin: 4rem 0 2rem 0;
  display: flex;
  flex: 1;
  position: relative;
`;

const Label = styled.h6`
  position: absolute;
  margin: 0;
  top: -2.5rem;
  font-size: 0.7em;
  color: rgb(205, 205, 205);
`;

const Input = styled(Field)`
  &&&& {
    display: flex;
    flex: 1;
    color: #4a4a4a;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
    padding: 0.8rem;
    font-size: 0.9em;
    margin-right: 1rem;
    &::placeholder {
      color: rgb(225, 225, 225);
    }
  }
`;

interface Guest {
  name: string,
  email?: string,
};

export interface IFormValues {
  guests: Array<Guest>,
};

const initialValues: IFormValues = { guests: [{ name: '', email: '' }] };

function validateEmail(value: string) {
  let error;
  if (!value) return;
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

function validateName(value: string) {
  let error;
  if (!value.length) {
    error = 'Required';
  }
  return error;
}

const RSVP: React.FC<{}> = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [coming, setComing] = useState(false);
  const [needsParking, setNeedsParking] = useState(false);
  const [open, setOpen] = useState(false);

  const sendEmail = (values: IFormValues, { setSubmitting, resetForm }: FormikHelpers<IFormValues>) => {
    Email.send({
      Host: "smtp.gmail.com",
      Username: 'l2mwedding820',
      Password: 'carondeletl2mwedding!',
      From : "l2mwedding820@gmail.com",
      To : "messer55@gmail.com",
      Subject : `New RSVPs! ${coming ? '(Attending)' : '(Not Attending)'}`,
      Body : `${JSON.stringify(values)} - parking: ${needsParking ? 'Yes' : 'No'}`,
    }).then(
      message => {
        setSubmitting(false);
        if (message === 'OK') {
          resetForm();
          setEmailSent(true);
          setOpen(false);
          return;
        } else {
          console.log(message);
          alert('Oops, something went wrong...maybe just let us know via alyssaylee@gmail.com or try again');
        }
      }
    );
  }

  return (
    <Container>
      {emailSent && coming && (
        <div>
          Thank you! We're excited to see you at our wedding :) 
        </div>
      )}
      {emailSent && !coming && (
        <div>
          Thank you for letting us know! We hope to see you soon after our wedding.
        </div>
      )}
      {!emailSent && (
        <Fragment>
            <p>We're looking forward to seeing you at our wedding! Please fill in the guests you'd like to RSVP below.</p>
            <Formik
              initialValues={initialValues}
              onSubmit={sendEmail}
              validateOnMount
              render={({ handleSubmit, isValid, values, isSubmitting }) => (
                <Form>
                  <FieldArray
                    name="guests"
                    render={(arrayHelpers) => (
                      <div>
                        {values.guests.map((guest, index) => (
                          <FieldContainer key={index}>
                              {index > 0 && (
                                <RemoveButton
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)} // remove a guest from the list
                                >
                                 <SvgCancel />
                                </RemoveButton>
                              )}
                            <InputContainer>
                              <Label>FULL NAME</Label>
                              <Input
                                name={`guests.${index}.name`}
                                validate={validateName}
                                placeholder="John Smith"
                              />
                            </InputContainer>
                            <InputContainer>
                              <Label>EMAIL</Label>
                              <Input
                                name={`guests.${index}.email`}
                                validate={validateEmail}
                                placeholder="johnsmith@gmail.com"
                              />
                            </InputContainer>
                          </FieldContainer>
                        ))}
                        <AddContainer 
                            onClick={() => arrayHelpers.push({ name: '', email: '' })}
                        >
                          <AddButton
                            type="button"
                          >
                            <Plus />
                          </AddButton>
                          <p>Add Guest</p>
                        </AddContainer>
                        <div>
                          <SendButton disabled={!isValid} type="button" onClick={() => setOpen(true)}>
                            RSVP
                        </SendButton>
                        <ConfirmationDialog
                          handleSubmit={handleSubmit}
                          open={open}
                          onClose={() => setOpen(false)}
                          values={values}
                          isSubmitting={isSubmitting}
                          setComing={setComing}
                          setNeedsParking={setNeedsParking}
                          needsParking={needsParking}
                        />
                        </div>
                      </div>
                    )}
                  />
                </Form>
              )}
            />
          </Fragment>
        )}
    </Container>
  );
};

export default RSVP;
