import React from 'react';
import { Link } from 'react-router-dom';
import questions from './questions';
import styled from 'styled-components';

const Container = styled.div`
  margin: 1rem 6rem;
  padding: 1rem;
  @media (max-width: 500px) {
    margin: 1rem;
  }
  > p {
    > a {
      &:hover {
        font-weight: 800;
        text-decoration: none;
        border: none;
        color: inherit;
      }
  }
  }
`;

const Block = styled.div`
  > h6 {
    margin-top: 2.5rem;
    margin-bottom: 0.6rem;
    font-size: 0.9em;
  }
  > p {
    margin-top: 0;
    > a {
      &:hover {
        border: none;
      }
    }
  }

`;

interface IQuestion {
  question: string;
  answer: string;
};

/**
 * Returns FAQ Markup
 * @param {string} question - question header text
 * @param {string} answer - answer text
 */
const Question = ({ question, answer }: IQuestion) => (
  <Block>
    <h6>{question}</h6>
    <p dangerouslySetInnerHTML={{ __html: answer }} />
  </Block>
);

const FAQ = () => {
  return (
    <Container>
      <p>If you have a question that isn't answered below, please <Link to="/contact">send us a note.</Link></p>
      {questions.map(({ question , answer }) => (
        <Question
          question={question.toUpperCase()}
          answer={answer} 
        />
      ))}
    </Container>
  )
}

export default FAQ; 
