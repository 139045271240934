import React from 'react';
import sweetpotato from './sweetpotato.gif';
import styled from 'styled-components';

const Container = styled.div`
  margin: 1rem 6rem;
  padding: 1rem;
  @media (max-width: 415px) {
    margin: 1rem;
  }
`;

const Gif = styled.img`
  border-radius: 10px;
`;

const ThankYou = () => {
    return (
        <Container>
            <p>Thank you for contributing to our home improvement!! Please have a sweet potato snack on us.</p>
            <Gif src={sweetpotato} alt="japan" />
        </Container>
    );
}

export default ThankYou;
