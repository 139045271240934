import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  Switch,
  Route,
  NavLink, 
} from 'react-router-dom';
import alyssa from './images/bride.jpg';
import chris from './images/groom.jpg';
import names from './images/names.jpg';
import message from './images/invitetext.png';
import venue from './images/venue.jpg';
import venueDescription from './images/carondelettext.png';
import FAQ from './FAQ/FAQ';
import Contact from './Contact';
import Photos from './Photos/Photos';
import Registry from './Registry';
import RSVP from './RSVP/RSVP';
import ThankYou from './ThankYou';
import photos from './imagesToPreload';
import { ReactComponent as Mail } from './mail.svg';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem 1rem 4rem 1rem;
  border-radius: 10px;
  min-height: 93vh;
  > nav {
    display: flex;
    justify-content: space-around;
    margin: 0 2rem 2rem 2rem;
    > a {
      color: #4a4a4a;
      &:hover {
        font-weight: 800;
        text-decoration: none;
        border: none;
        color: inherit;
      }
    }
  }
`;

const Portraits = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  margin: 0 2rem;
`;

const Portrait = styled.img`
  width: 184px;
  height: 242px;
`;

const Names = styled.img`
  display: flex;
  align-content: center;
  margin: -3rem auto 0 auto;
  width: 448px;
  height: 87px;
`;

const Message = styled.img`
  display: flex;
  align-content: center;
  margin: 0 auto;
  width: 435px;
  height: 149px;
`;

const Venue = styled.img`
  display: flex;
  align-content: center;
  margin: 0 auto;
  width: 368px;
  height: 219px;
`;

const VenueDescription = styled.img`
  display: flex;
  align-content: center;
  margin: 0 auto;
  width: 350px;
  height: 44px;
`;

const SvgLink = styled(NavLink)`
  > svg {
    fill: #4a4a4a;
    width: 25px;
    height: 25px;
    margin-top: 0.1rem;
    @media (max-width: 500px) {
      margin-top: 0.2rem;
      width: 20px;
      height: 20px;
    }
  }
  &.svg-link {
    > svg {
      fill: #dda850;
    }
  }
`;

const App: React.FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => {
    const imageList = photos;
    setTimeout(() => imageList.forEach((img: any) => new Image().src = img.src), 2000);
  }, [])
  return (
    <Container>
      <nav>
        <NavLink activeStyle={{ color: '#dda850', fontFamily: 'metropolismedium' }} to="/faq">FAQ</NavLink>
        <NavLink activeStyle={{ color: '#dda850', fontFamily: 'metropolismedium' }} to="/rsvp">RSVP</NavLink>
        <NavLink activeStyle={{ color: '#dda850', fontFamily: 'metropolismedium' }} to="/registry">REGISTRY</NavLink>
        <NavLink activeStyle={{ color: '#dda850', fontFamily: 'metropolismedium' }} to="/photos">PHOTOS</NavLink>
        <SvgLink activeClassName="svg-link" to="/contact">
          <Mail />
        </SvgLink>
      </nav>
      <Portraits onClick={() => history.push('/')}>
        <Portrait src={alyssa} alt="alyssa" />
        <Portrait src={chris} alt="chris" />
      </Portraits>
      <Names src={names} alt="names" />
      <Switch>
        <div>
        <Route exact path="/">
          <Message src={message} alt="message" />
          <a
            href="https://www.google.com/maps/place/Carondelet+House/@34.060765,-118.280391,15z/data=!4m2!3m1!1s0x0:0xc654d5d9e6fcddff?sa=X&ved=2ahUKEwjH8ZewwoDqAhXUo54KHcwFCP0Q_BIwG3oECBMQCA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Venue src={venue} alt="venue" />
            <VenueDescription src={venueDescription} alt="venueDescription" />
          </a>
        </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/photos">
            <Photos photos={photos} />
          </Route>
          <Route path="/registry">
            <Registry />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/thank-you">
            <ThankYou />
          </Route>
          <Route path="/rsvp">
            <RSVP />
          </Route>
        </div>
      </Switch>
    </Container>
  );
}

export default withRouter(App);
