export default [

  {
    question: 'NOTE: RESCHEDULED FOR AUGUST 20, 2021',
    answer: `
      Our wedding has officially been rescheduled to Friday, August 20, 2021!
      Please note that in order to hold our event we will need all guests to follow specific state and county guidelines.
      Please see below for those details.
    `,
  },
  {
    question: 'What should I wear?',
    answer: 'The dress code for our wedding is semi-formal/cocktail attire. No jeans please!',
  },
  {
    question: 'Where are the ceremony and the reception taking place?',
    answer: 'Both the ceremony and reception will take place at the <a href="https://www.google.com/maps/place/Carondelet+House/@34.060765,-118.280391,15z/data=!4m2!3m1!1s0x0:0xc654d5d9e6fcddff?sa=X&ved=2ahUKEwjH8ZewwoDqAhXUo54KHcwFCP0Q_BIwG3oECBMQCA" target="_blank">Carondelet House</a>.',
  },
  {
    question: 'Will the ceremony and reception be indoors or outdoors?',
    answer: 'The ceremony and reception will be indoors. There will also be 2 smaller outdoor spaces available for guests to use. The indoor capacity is 150 and we don’t expect to have any more than 50 guests, so there should be plenty of space for everyone.',
  },
  {
    question: 'What happens after the ceremony?',
    answer: `
      There will be a cocktail hour immediately following the ceremony,
      where we will be serving passed appetizers and beverages.
      Dinner and the reception will begin at 7 pm and end promptly at 11 pm.
    `,
  },
  {
    question: 'Are there other wedding events I can attend?',
    answer: 'Just the wedding! There will be a rehearsal dinner a few days before for those in the processional but the date is TBD.',
  },
  {
    question: 'What time should I arrive?',
    answer: 'The ceremony will begin at 5 pm on Friday, August 20th. We recommend that you arrive 15 minutes prior so we can get started on time.'
  },
  {
    question: 'Will parking be available?',
    answer: 'Parking will be free of charge in the lot next door. Please let us know in advance if you plan to bring a car so we can reserve a parking spot for you.',
  },
  {
    question: 'Have you reserved hotel room blocks?',
    answer: 'We haven’t reserved any hotel room blocks but we recommend staying at the Line LA. It’s only one mile from our venue and in the heart of Koreatown.',
  },
  {
    question: 'When should I RSVP by?',
    answer: 'Please RSVP by <strong><span style="color:black">July 20</span></strong> using our <a href="https://l2mwedding.com/rsvp">RSVP form</a>.',
  },
  {
    question: 'Will there be food and drinks served?',
    answer: 'We will have passed appetizers, a full open bar, as well as a 3-course meal, and dessert.',
  },
  {
    question: 'Will there be dancing?',
    answer: 'Given the current climate, we don\'t expect too much.',
  },
];
