import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { ReactComponent as GiftTwo } from './gift-two.svg';
import { ReactComponent as House } from './house.svg';

const PUBLIC_KEY = 'pk_live_51GztiCF4dM3K5KKjjnf4ZJcQ6s9U9cu899nYhBYDGZxklyCQUTN9DE4SucuGleqNgGzH0nq7fD9llFj3hDf3VQ5A00dWexerT3';
const HOME_50 = 'price_1GzvjeF4dM3K5KKjaE9nTGI6';
const HOME_100 = 'price_1GzvjeF4dM3K5KKj2xEzakuk';
const HOME_200 = 'price_1H0XW9F4dM3K5KKjOb5IlMYv';
const HOME_250 = 'price_1H09hpF4dM3K5KKj0bxxqdOi';

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  margin: 1rem 6rem;
  padding: 1rem;
  @media (max-width: 500px) {
    margin: 1rem;
  }
`;

const DonateBtn = styled.button`
  display: flex;
  background-color: #f8c0c4;
  margin-right: 1rem;
  margin-top: 2rem;
  width: 50px;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.8em;
  border-radius: 500px;
  padding: 1rem;
  font-weight: 600;
  transition: 0.3s all;
  &:hover {
    background-color: #ff9ca3;
  }
`;

const ReigstryItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  > div {
    margin-left: 3rem;
    h6 {
      margin-bottom: 0.5rem;
    }
    > p {
      > a {
        &:hover {
          font-weight: 800;
          text-decoration: none;
          border: none;
          color: inherit;
        }
      }
    }
  }
  > span {
    margin-bottom: 1rem;
    @media (max-width: 684px) {
      ${({ propUp }) => propUp && `
        margin-bottom: 6rem;
      `}
    }
  }
`;

const Registry = () => {
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    const getStripe = async () => {
      const client = await loadStripe(PUBLIC_KEY);
      setStripe(client);
    }
    getStripe();
  }, [])
  const donateItem = (price) => {
    stripe.redirectToCheckout({
      lineItems: [
        {
          price,
          quantity: 1,
        },
      ],
      mode: 'payment',
      cancelUrl: 'https://l2mwedding.com/registry',
      successUrl: 'https://l2mwedding.com/thank-you',
    }).then(() => console.log('oops!'));
  }
  return (
    <Container>
      <p>We're lucky to say that our home already has most things we need, so we did not go with a traditional registry.</p>
      <p>If you would like to give us a gift, please find a few alternative options below:</p>
      <ReigstryItemContainer>
        <span style={{ padding: '0 10px' }}>
          <GiftTwo />
        </span>
        <div>
          <h6>Wedding gift</h6>
          <p>If you would like to send us a card or give us a gift, we’d be happy to accept them on the day of or to our home in San Francisco. Please find our mailing address on our <Link to="/contact">contact us</Link> page.</p>
        </div>
      </ReigstryItemContainer>
      <ReigstryItemContainer propUp>
        <span>
          <House />
        </span>
        <div>
          <h6>Home improvement fund</h6>
          <p style={{ marginBottom: 0 }}>We would be grateful for a contribution towards improving our home in the near future.</p>
          <ButtonRow>
            <DonateBtn onClick={() => donateItem(HOME_50)}>$50</DonateBtn>
            <DonateBtn onClick={() => donateItem(HOME_100)}>$100</DonateBtn>
            <DonateBtn onClick={() => donateItem(HOME_200)}>$200</DonateBtn>
            <DonateBtn onClick={() => donateItem(HOME_250)}>$250</DonateBtn>
          </ButtonRow>
        </div>
      </ReigstryItemContainer>
    </Container>
  )
}

export default Registry;
