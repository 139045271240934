import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { IFormValues } from './RSVP';
import { ReactComponent as SvgCancel } from './cancel.svg';
import styled from 'styled-components';

const PaperProps = {
    style: {
        backgroundColor: '#f9f9f9',
        width: '85%',
        maxWidth: '700px',
        height: '60%',
        transition: '0s all',
    },
};

const Button = styled.button`
  display: flex;
  margin-left: auto;
  margin-top: 1rem;
  background-color: #f8c0c4;
  border: none;
  font-size: 1em;
  border-radius: 5px;
  padding: 1rem 2rem;
  font-weight: 600;
  margin-right: 1rem;
  &:hover {
    background-color: #ff9ca3;
  }
`;

const CancelButton = styled.button`
  align-self: flex-start;
  width: 30px;
  height: 30px;
  border-radius: 500px;
  border: none;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  display: flex;
  background-color: inherit;
  > svg {
    fill: rgb(105, 105, 105);
    &:hover {
      fill: #f8c0c4;
    }
  }
`;

export interface ConfirmationDialogProps {
    isSubmitting: boolean;
    handleSubmit: any;
    open: boolean;
    onClose: (value?: string) => void;
    values: IFormValues;
    setComing: (value: boolean) => void;
    setNeedsParking: (value: boolean) => void;
    needsParking: boolean;
};

function ConfirmationDialog(props: ConfirmationDialogProps) {
    const { isSubmitting, handleSubmit, onClose, open, values, setComing, setNeedsParking, needsParking } = props;
  
    const handleCancel = () => {
      onClose();
    };

    const handleOk = () => {
        setComing(true);
        setTimeout(() => handleSubmit());
    };

    const handleNo = () => {
      handleSubmit();
    }
  
    return (
      <Dialog
        open={open}
        onBackdropClick={handleCancel}
        onEscapeKeyDown={handleCancel}
        PaperProps={PaperProps}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1rem' }}>
          <h4 style={{ marginLeft: '1.7rem', letterSpacing: '5px' }}>RSVP</h4>
          <CancelButton onClick={handleCancel}>
            <SvgCancel />
          </CancelButton>
       </div>
        <DialogContent>
            <table>
                <thead>
                    <tr style={{ fontSize: '0.7em', color: 'rgb(145, 145, 145)' }}>
                        <td>NAME</td>
                        <td>EMAIL</td>
                    </tr>
                </thead>
                <tbody>
                {values.guests.map(guest => {
                    return (
                        <tr>
                            {Object.values(guest).map(val => <td key={val}>{val}</td>)}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </DialogContent>
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '3rem', paddingTop: '1rem' }}>
          <br />
          <input onChange={() => setNeedsParking(!needsParking)} type="checkbox" />
          <span style={{ paddingLeft: '0.8rem' }}> I / We require a parking space</span>
        </div>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={handleNo}>
            Decline
          </Button>
          <Button type="button" disabled={isSubmitting} onClick={handleOk}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

export default ConfirmationDialog;
