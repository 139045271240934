import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 8rem 2rem 0rem 2rem;
`;

const ImageContainer = styled.div<{ portrait: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${({ portrait }) => portrait ? '250px' : '500px'};
  @media (max-width: 500px) {
    max-width: 330px;
  }
  > h6 {
    margin: 0;
  }
`;

/**
 * Extendable image component
 * @param {boolean} portrait - adjust max-width based on
 */
const Image = styled.img<{ portrait: boolean }>`
  border-radius: 10px;
  max-width: ${({ portrait }) => portrait ? '250px' : '500px'};
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    max-width: 350px;
  }
  @media (min-width: 501px) {
    ${({ portrait }) => portrait && `
      max-height: 330px; 
    `}
  }
`;

const Photos = ({ photos }: { photos: any[] }) => {
  return (
    <Container>
      {photos.map((photo) => {
        return (
          <ImageContainer portrait={photo.portrait}>
            <Image portrait={photo.portrait} key={photo.src} src={photo.src} alt="l2m" />
            <h6>{photo.date}</h6>
            <p>{photo.description}</p>
          </ImageContainer>
        );
      })}
    </Container>
  );
}

export default Photos;
