import React, { Fragment, useState } from 'react';
import Email from './smtp';
import { Formik, Field } from "formik";
import styled from 'styled-components';
import { Redirect } from 'react-router';

const Container = styled.div`
  margin: 1rem 6rem;
  padding: 1rem;
  @media (max-width: 415px) {
    margin: 1rem;
  }
`;

const Input = styled(Field)`
  &&&& {
    display: flex;
    flex: 1;
    color: #4a4a4a;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
    padding: 0.8rem;
    font-size: 0.9em;
    margin-right: 1rem;
    &::placeholder {
      color: rgb(225, 225, 225);
    }
  }
`;

const TextArea = styled.textarea`
    display: flex;
    flex: 1;
    color: #4a4a4a;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
    padding: 0.8rem;
    font-size: 0.9em;
    margin-right: 1rem;
    &::placeholder {
      color: rgb(225, 225, 225);
    }
`;

const InputContainer = styled.div`
  margin: 4rem 0 2rem 0;
  display: flex;
  flex: 1;
  position: relative;
  ${({ disable }) => disable && `
    pointer-events: none;
    opacity: 0.4;
  `}
`;

const Label = styled.h6`
  position: absolute;
  margin: 0;
  top: -2.5rem;
  font-size: 0.7em;
  color: rgb(205, 205, 205);
`;

const SendButton = styled.button`
  display: flex;
  margin-left: auto;
  margin-top: 1rem;
  background-color: #f8c0c4;
  border: none;
  font-size: 1em;
  border-radius: 5px;
  padding: 1rem 2rem;
  font-weight: 600;
  &:hover {
    background-color: #ff9ca3;
  }
`;

function validateValue(value) {
  let error;
  if (!value.length) {
    error = 'Required';
  }
  return error;
}

function validateEmail(value) {
  let error;
  if (!value) return;
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}

const Note = () => {
  const [emailSent, setEmailSent] = useState(false);
  const sendEmail = (values, { setSubmitting, resetForm }) => {
    Email.send({
      Host: "smtp.gmail.com",
      Username: 'l2mwedding820',
      Password: 'carondeletl2mwedding!',
      From : "l2mwedding820@gmail.com",
      To : "messer55@gmail.com",
      Subject : `A note from ${values.user}`,
      Body : `email: ${values.email}\n note: ${values.note}`,
    }).then(
      message => {
        setSubmitting(false);
        if (message === 'OK') {
          resetForm();
          setEmailSent(true);
          return;
        } else {
          alert('Oops, something went wrong...maybe just let us know via alyssaylee@gmail.com or try again');
        }
      }
    );
  }
    return (
        <Container>
            {emailSent && (
                <Redirect to="/" />
            )}
            {!emailSent && (
                <Fragment>
                    <p>Write any questions or comments below to e-mail us</p>
                    <Formik
                        initialValues={{ email: '', user: '', note: '' }}
                        validateOnMount
                        onSubmit={sendEmail}
                        render={({ handleSubmit, isValid, isSubmitting }) => {
                            return (
                                <Fragment>
                                    <InputContainer disable={isSubmitting}>
                                            <Label>Email</Label>
                                            <Input
                                                name="email" 
                                                validate={validateEmail}
                                                placeholder="dino@gmail.com"
                                            />
                                    </InputContainer>
                                    <InputContainer disable={isSubmitting}>
                                            <Label>From</Label>
                                            <Input
                                                name="user" 
                                                validate={validateValue}
                                                placeholder="Dino Moraites"
                                            />
                                    </InputContainer>
                                    <InputContainer disable={isSubmitting}>
                                            <Label>Note</Label>
                                            <Input
                                                name="note" 
                                                placeholder="What should I say when I officiate this wedding?"
                                            >
                                                {({ field }) => (
                                                    <TextArea placeholder="What should I say when I officiate this wedding?" {...field} />
                                                )}
                                            </Input>
                                    </InputContainer>
                                    <SendButton onClick={handleSubmit} disabled={!isValid || isSubmitting} >
                                        Send
                                    </SendButton>
                                </Fragment>
                            );
                        }}
                    />
                </Fragment>
            )}
            <p style={{ marginBottom: '0.5rem', marginTop: '5rem' }}>Want to send mail instead? Our address is:</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>821 Folsom Street Unit 112</p>
            <p style={{ marginTop: 0, fontStyle: 'italic' }}>San Francisco, CA 94107</p>
        </Container>
    );
}

export default Note;
