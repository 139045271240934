// try preloading images
import blowhole from './Photos/images/blowhole.gif';
import vlog from './Photos/images/vlog.gif';
import first from './Photos/images/first.jpg';
import clubbing from './Photos/images/clubbing.jpg';
import portland from './Photos/images/portland.jpg';
import maui from './Photos/images/maui.jpg';
import iaovalley from './Photos/images/iaovalley.jpg';
import children from './Photos/images/children.jpg';
import colorado from './Photos/images/colorado.jpg';
import engaged from './Photos/images/engaged.jpg';
import applepicking from './Photos/images/applepicking.jpg';
import spring2013 from './Photos/images/spring2013.jpg';
import nyc from './Photos/images/nyc.jpg';
import engagedGif from './Photos/images/engaged.gif';
import observatory from './Photos/images/observatory.jpg';
import osaka from './Photos/images/osaka.jpg';
import hapan from './Photos/images/hapan.jpg';
import hawaiiSelfie from './Photos/images/hawaii-selfie.jpg';
import londonPark from './Photos/images/london-park.jpg';

export default [
    { 
        date: 'Boston - Halloween 2012',
        src: first,
        description: 'Our very first photo together. Alyssa\'s sailor costume is much better than the phoned-in harry potter.',
        portrait: true,
    },
    { 
        date: 'Boston - Apr 2013',
        src: clubbing,
        description: 'Back when we used to go out!',
        portrait: true,
    },
    {
        date: 'Boston - Apr 2013', 
        src: children,
        description: 'Boston Commons during tulip season!!!',
    },
    {
        date: 'Boston - May 2013', 
        src: spring2013,
        description: 'Right after we both graduated.',
    },
    {
        date: 'Vermont - Oct 2016',
        src: applepicking,
        portrait: true,
        description: 'Apple picking during the fall in Vermont.',
    },
    {
        date: 'NYC - Nov 2016',
        src: nyc,
        description: 'We were into gray that day.',
        portrait: true,
    },
    {
        date: 'Toronto - Nov 2016',
        src: vlog,
        description: 'Aspiring travel vloggers. We thought the sidewalks were empty but then we realized others heard us.'
    },
    {
        date: 'Portland (OR) - Jul 2017',
        src: portland,
        description: 'Our first trip after we moved to SF. Pictured here: us waiting in line for some food.',
    },
    {
        date: 'Denver - Nov 2018',
        src: colorado,
        description: 'Denver was very cold. Alyssa bought Chris\'s jacket for this trip, we returned it after.',
    },
    {
        date: 'Tokyo - Apr 2019',
        src: engaged,
        description: 'Just got engaged! It was a long morning, and after many failed attempts at popping the question, it finally happened.',
        portrait: true
    },
    {
        date: 'Osaka - Apr 2019',
        src: osaka,
        portrait: true,
    },
    {
        date: 'Japan - Apr 2019',
        src: hapan,
        description: 'Miyajima island. Alyssa in a nice spring outfit, Chris in pajamas.',
    },
    {
        date: 'London - Aug 2019',
        src: londonPark,
        portrait: true,
        description: 'First trip to London, Alyssa has not been the same since. Her favorite city.',
    },
    {
        date: 'London - Nov 2019',
        src: observatory,
        portrait: true,
        description: 'Second trip, colder and at the Royal Observatory.',
    },
    {
        date: 'Maui - Dec 2019',
        src: maui,
        description: 'We nearly died on this raft. Those are the smiles of people relieved to still be alive.',
    },
    {
        date: 'Nakalele blowhole - Dec 2019',
        src: blowhole,
        description: 'Best reaction from Alyssa.'
    },
    {
        date: 'Maui - Dec 2019',
        src: hawaiiSelfie,
        description: 'Road to Hana mid-point, this is as far as we got.',
        portrait: true,
    },
    {
        date: 'Maui - Dec 2019',
        src: iaovalley,
        description: 'Iao Valley',
        portrait: true,
    },
    {
        date: 'Holiday Party - Jan 2020',
        src: engagedGif,
        description: 'A quick practice ceremony with our officiant. We\'re ready!',
    },
];